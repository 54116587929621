.container {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.top-banner {
  @include card-text_public;
  background-color: #053ac8;
  top: 0;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  color: white;
}
