.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_title {
    @include subtitle-rubik;
    color: $gray;
    font-weight: 500;
  }

  .modal_body {
    @include span-public;
    color: $gray;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .modal_input {
    @include span-public;
    padding: 32px 0;
    margin: auto;

    input {
      @include span-public;
      font-weight: 700;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .modal-error {
    margin: 24px 0;
    color: $white;
    @include span-public;
  }

  .modal-resend {
    gap: 4px;
    display: flex;
    margin-right: auto;
    align-items: center;
    justify-content: center;

    button {
      background: none;
      color: #0069f5;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0;
    }

    span {
      color: #98a2b3;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .modal-button_cancel {
    background: none;
    color: #686868;
    text-transform: lowercase;
    font-family: $public-sans;
    font-size: 14px;
    padding: 8px 32px;
    border-radius: 6px;
    border: none;
  }

  .modal-button_success {
    background-color: $green;
    color: $white;
    text-transform: lowercase;
    font-family: $public-sans;
    font-size: 14px;
    padding: 8px 32px;
    border-radius: 6px;
    border: none;
  }
}

.rs-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
