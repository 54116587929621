.create-form {
  padding-top: 40px;
}

.form-group {
  margin: 10px 0;

  .form-label {
    @include span-public;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    margin-bottom: 8px;

    i {
      font-weight: 400;
    }
  }

  svg,
  path {
    width: 20px;
    height: 20px;
    stroke: white;
  }

  .form-input {
    @include label-public;
    width: 100%;
    background-color: #0235bd;
  }

  .form-input::placeholder {
    color: $white;
    opacity: 1;
  }

  .form-icon {
    background-color: #0235bd;
  }

  .checkbox-group {
    @include label-public;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .checkbox-option {
      color: $white;
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
  }

  .check-terms {
    @include label-public;
    color: $white;

    a {
      color: #026aef;
    }
  }
}

.form-areas {
  border: 1px solid rgba(83, 177, 253, 0.2);
  border-radius: 4px;
  padding: 10px;
  background-color: #0235bd;
}

// substituindo estilização padrão da rsuite
.rs-picker-toggle-wrapper,
.rs-form-control-wrapper>.rs-input {
  @include label-public;
  // background-color: $turivius-light;
  border-radius: 4px;
  width: 100%;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  // background-color: transparent !important;
  top: 12px;
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 12px;
  right: 48px;
}

.rs-picker-default .rs-picker-toggle.rs-btn,
.rs-input {
  @include label-public;
  padding: 12px;
  color: $white;
  //border: 1px solid #84D4FF;
  border-radius: 4px;
  width: 100%;

  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }
}

.rs-picker-menu .rs-picker-search-bar {
  @include label-public;
}

.rs-picker-select-menu-item {
  @include label-public;
}

.checkbox-group .rs-checkbox-group,
.rs-checkbox-checker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.rs-theme-light,
:root {
  --rs-checkbox-checked-bg: #026aef;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  background-color: $turivius-light;
  color: $white;
}

.rs-form-vertical .rs-form-group .rs-input-group {
  border-color: rgba(83, 177, 253, 0.2);
  width: auto;
  // border: 1px solid red !important;
}

.dropdown {
  border: 1px solid rgba(83, 177, 253, 0.2);
  background-color: #0235bd;
}

.rs-input-group-addon {
  background-color: $turivius-light;
}

.rs-picker-toggle-caret,
.rs-input-group-addon {
  color: $white;
}

.rs-picker-toggle .rs-btn .rs-btn-default {
  background-color: $turivius-light;
  color: #026aef;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: transparent !important;
  border: none !important;
  // border: 1px solid red;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: $white !important;
}

// .rs-form-control {
//   border: 1px solid red !important;
// }
