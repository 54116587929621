.demonstration {
  padding-top: 20px;
  color: $gray;
  @include span-public;
  text-align: left;
  font-weight: 400;

  b {
    font-weight: 600;
  }

  svg,
  path {
    vertical-align: middle;
    margin-right: 10px;
  }

  .call {
    svg,
    path {
      width: 17px;
      height: 17px;
      stroke: #53b1fd;
    }
  }

  a:link {
    color: $gray;
    text-decoration: none;
  }

  a:visited {
    color: $gray;
    text-decoration: none;
  }

  a:hover {
    color: $gray;
    text-decoration: none;
  }

  a:active {
    color: $gray;
    text-decoration: none;
  }
}
