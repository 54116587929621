$rubik: 'Rubik', sans-serif;

$public-sans: 'Public Sans', sans-serif;

$poppins: 'Poppins', sans-serif;

@mixin title-rubik() {
  font-family: $rubik;
  font-size: 25px;
  line-height: 46px;
}

@mixin subtitle-rubik() {
  font-family: $rubik;
  font-size: 20px;
  line-height: 123%;
}

@mixin subtitle-poppins() {
  font-family: $poppins;
  font-size: 20px;
  line-height: 123%;
}

@mixin card-title_rubik() {
  font-family: $rubik;
  font-size: 20px;
  line-height: 120%;
}

@mixin span-rubik() {
  font-family: $rubik;
  font-size: 16px;
  line-height: 24px;
}

@mixin span-public() {
  font-family: $poppins;
  font-size: 16px;
  line-height: 22px;
}

@mixin card-text_public() {
  font-family: $poppins;
  font-size: 16px;
  line-height: 22px;
}

@mixin testimonial-text_public() {
  font-family: $poppins;
  font-size: 16px;
  line-height: 22px;
}

@mixin label-public() {
  font-family: $poppins;
  font-size: 16px;
  line-height: 22px;
}
