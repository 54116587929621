.button {
  @include subtitle-poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: $gray;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 36px;
  margin-top: 25px;
  &.disabled {
    div.element-group {
      display: inline-flex;
      align-items: center;

      p {
        margin: 0px;
        margin-left: 5px;
        color: $white;
      }
    }
  }
  &:hover {
    background-color: #02032f;
    color: #84d4ff;
    .material-icons {
      color: $white;
    }
  }
  .material-icons {
    color: $turivius-dark;
  }
}

.button-top {
  display: none;
}
@media (max-width: 575px) {
  .button-top {
    @include subtitle-poppins;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $turivius-light;
    color: $white;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 36px;
    margin-top: 25px;
    &.disabled {
      div.element-group {
        display: inline-flex;
        align-items: center;

        p {
          margin: 0px;
          margin-left: 5px;
          color: $white;
        }
      }
    }
    &:hover {
      background-color: #02032f;
      color: #84d4ff;
      .material-icons {
        color: $white;
      }
    }
    .material-icons {
      color: $turivius-dark;
    }
  }
}
