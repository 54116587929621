$turivius: #00007d;
$turivius-light: #053ac8;
$turivius-dark: #02032f;
$secondary: #0069ef;
$secondary-light: #0d91fe;
$secondary-dark: #0069f5;
$turivius-hot: #ea9611;
$red: #e61e25;
$pastelred: #ff6961;
$green: #15c16b;
$black: #1d1d1b;
$black-deep: #000000;
$white: #ffffff;
$gray: #454545;
$gray-light: #929493;
$body-color: #f4f5ff;
$darker-body-color: #d6d7e9;
$link-color: #039be5;
$light-blue: #84d4ff;
$lighter-blue: #eff8ff;
