.about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .about-us_title {
    @include title-rubik;
    color: $gray;
    font-weight: 400;
  }

  .about-us_testimonial {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .testimonial-img {
      z-index: 1;
    }

    .testimonial-text {
      width: 70%;
      @include testimonial-text_public;
      color: $gray;
      padding-bottom: 24px;
    }

    .testimonial-name {
      display: flex;
      flex-direction: column;
      color: $gray;
      @include card-text_public;

      b {
        @include span-public;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }
}
