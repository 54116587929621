.carousel-container {
  .carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    .img-container {
      flex: 33%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
