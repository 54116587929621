.who {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .who-title {
    @include subtitle-rubik;
    color: $turivius-dark;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    text-align: left;
    width: 100%;

    strong {
      font-weight: bold;
    }
  }

  .who-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
  }
}

@media (max-width: 600px) {
  .who {
    display: block;
    margin: 50px 0;

    .who-use_title {
      padding: 0;
      margin-bottom: 20px;
    }

    .who-images {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
