.checkout {
  background-color: $lighter-blue;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
}

.create-account_section {
  padding-top: 96px;
}

.section-why {
  padding-top: 96px;
  padding-bottom: 48px;
}

.section-create {
  width: 50%;
  background-color: $turivius-light;
}

.section-about {
  width: 50%;

  .column {
    width: 100%;
    display: flex;
    justify-content: left;
    padding-left: 150px;

    .column-about {
      max-width: 559px;
      padding-top: 64px;

      .subtitle {
        display: block;
        @include title-rubik;
        color: $turivius-dark;
        line-height: 32px;
        margin-bottom: 40px;

        strong {
          font-weight: bold;
        }
      }

      .subtitle-mobile {
        display: none;
      }

      .why-use {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}

.button_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.swal2-html-container {
  @include card-title_rubik;
}

.swal2-styled.swal2-cancel,
.swal2-styled.swal2-confirm {
  @include card-text_public;
}

@media (max-width: 1580px) {
  .section-about {
    .column {
      padding-left: 0;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
  }
}

@media (max-width: 1280px) {
  .section-about {
    width: 100%;
    display: block;

    .column-about {
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      padding-inline: 24px;
      padding-bottom: 40px;
      max-width: 100% !important;

      .who-images {
        row-gap: 24px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .section-create {
    width: 100%;
    padding-bottom: 50px;
  }
}
