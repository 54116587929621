.use-terms {
  background-image: url(../../assets/background-new.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 25px;
  padding-top: 25px;
  text-align: justify;
}

.use-terms h4 {
  @include title-rubik;
}

.use-terms p {
  @include card-text_public;
  margin-top: 15px;
  margin-bottom: 15px;
}

.use-terms ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.use-terms li {
  @include card-text_public;
}

.use-terms b {
  @include card-text_public;
  font-weight: bolder;
}
