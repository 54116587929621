.why-turivius {
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(2, 1fr);

  svg,
  path {
    width: 20px;
    height: 20px;
  }

  .why-turivius_title {
    @include title-rubik;
    color: $gray;
    font-weight: 400;
  }

  .why-turivius_card {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 24px 40px -22px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    flex: 1 1 0px;

    .card-text {
      @include card-text_public;
      color: $gray;
      padding: 10px 20px 0 20px;
      line-height: 24px;
    }
  }
}

@media (max-width: 1280px) {
  .why-turivius {
    grid-template-columns: 1fr;

    .why-turivius_card {
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 24px !important;

      .card-text {
        @include card-text_public;
        color: $gray;
        padding: 0;
        width: 80%;
        margin-left: 20px;
        text-align: left;
      }
    }
  }
}
