.create-account {
  display: flex;
  justify-content: center;
  .account-form {
    width: 655px;
    min-height: 100vh;
    margin-top: 24px;
    //box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding: 40px 48px;

    .account-title {
      @include subtitle-rubik;
      color: $white;
      text-align: left;

      b {
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
      }
    }

    .account-subtitle {
      color: $white;
      margin: 8px 0 0 0;
      @include subtitle-poppins;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      width: 85%;
    }

    .accept {
      margin: 16px 0 0 0;
      color: $white;
      @include span-public;
      font-size: 12px;

      a {
        color: $turivius-dark;
      }
    }

    a {
      text-align: left;
      .logo-turivius {
        width: 200px;
        margin-bottom: 25px;
      }
    }
  }

  .divisor {
    border-top: 1px solid #84d4ff;
  }
}
//responsividade do container
@media (max-width: 575px) {
  .create-account {
    .account-form {
      padding: 24px;
      width: 100%;
    }
  }
  .accept {
    font-size: 12px;
  }
}
@media (max-width: 1366px) {
  .create-account {
    .account-form {
      padding-top: 24px;
    }
  }
}
@media (min-width: 1441px) {
  .create-account {
    justify-content: right;
    .account-form {
      padding: 40px 0px;
      padding-right: 150px;
    }
  }
}
