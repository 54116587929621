@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.grid-container {
  padding: 30px 150px;
  font-family: 'Poppins', sans-serif;

  &_title-container {
    gap: 16px;
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 200px;
    }
  }

  .logout-button {
    background-color: #053ac8;
    color: #fff;
    border-radius: 4px;
    padding: 10px 30px;
  }

  &_title-container {
    line-height: 40px;

    .title-top,
    .title-bottom {
      font-size: 32px;
    }

    .title-top {
      color: $turivius-dark;
      margin-bottom: -10px;
    }

    .title-bottom {
      color: $turivius-light;
    }
  }

  &_steps-container {
    display: flex;
  }

  &_card-step {
    background: #ffffff;
    box-shadow: 0px 13px 30px rgba(208, 213, 221, 0.4);
    border-radius: 16px;
    padding: 24px 16px;
    height: 100%;

    .section-header {
      display: flex;
      align-items: center;
      gap: 20px;
      min-height: 70px;

      &_number-section {
        width: 15%;

        .number {
          height: 44px;
          width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #d0d5dd;
          border-radius: 50px;
          font-size: 16px;
          font-weight: 700;
          color: $turivius-light;
        }
      }

      &_step-section {
        width: 85%;

        .step {
          color: #101828;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .section-body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-top: 12px;

      .message {
        width: 80%;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &_footer-container {
    gap: 8px;
    display: flex;
    flex-direction: column;

    h5 {
      color: #101828;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #667085;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .grid-container {
    &_title-container {
      margin-bottom: 0;
    }

    &_card-step {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_steps-container {
      gap: 24px;
      flex-direction: column;

      .section-header {
        min-height: auto;

        &_number-section {
          width: auto;
        }
      }

      .section-body {
        margin-top: 0;
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 948px) {
  .grid-container {
    padding: 40px;

    &_header {
      img {
        max-width: 130px;
      }
    }
  }
}
